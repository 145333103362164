
import log from "loglevel";

export function Breadcrumbs ( props ) {

	// simplify processing below by just handling the root case specially
	if ( props.modelPath === "/" || props.modelPath === "" )
	{
		return <span key="path-/">(root)</span>;
	}

	var linkList = [];
	var navToPath = "/model";
	var pathList = props.modelPath.split ( "/" );

	log.info ( "breadcrumb pathlist: " + JSON.stringify ( pathList ) + " from path " + props.modelPath );

	linkList.push ( <span key="path=/" > <a href="/model" >(root)</a> /</span> );
	for ( var i = 0; i < pathList.length; i++ )
	{
		navToPath = navToPath + "/" + pathList[i];

		if ( i === pathList.length - 1 )
		{
			linkList.push ( <span key={"path-"+pathList[i]} > {pathList[i]}</span> );
		}
		else
		{
			linkList.push ( <span key={"path-"+pathList[i]} > <a href={navToPath} >{pathList[i]}</a> / </span> );
		}
	}

	return (
		<>
			{linkList}
		</>
	);
}
