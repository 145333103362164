
import { Leftbar } from "../../components/leftbar/Leftbar.js";
import './Settings.css';

export function Settings ( props )
{
	return <div className="row">
		<div className="col-1">
			<Leftbar selected="settings" />
		</div>
		<div className="col-11 TopPane">
			(settings)
		</div>
	</div>
}
