
import { Leftbar } from "../../components/leftbar/Leftbar.js";
import { ModelBrowser } from "../../components/modelbrowser/ModelBrowser.js";
import { useParams } from 'react-router-dom';

export function ModelBrowserScene ( props )
{
	let params = useParams();
	var modelPath = params["*"];

	return <div className="row">
		<div className="col-1">
			<Leftbar selected="model" />
		</div>
		<div className="col-10">
			<ModelBrowser modelPath={modelPath} />
		</div>
		<div className="col-1">
		</div>
	</div>
}
