
import { createContext, useContext } from 'react';
import log from 'loglevel';
import { jwtDecode } from "jwt-decode";

const authRecordStorageName = "continualAuthRecord";

// create our singleton auth context
export const AuthContext = createContext ();

/**
 * returns the current value for the auth context 
 */
export function useAuth ()
{
	return useContext ( AuthContext );
}

/**
 * Is the user logged in? This doesn't validate the JWT token, it just checks for the presence of the
 * token and that it hasn't expired.
 */
export function isLoggedIn ( authRecord )
{
	if ( authRecord && authRecord.status === "ok" && authRecord.token )
	{
		const { exp } = jwtDecode ( authRecord.token );
		if ( Date.now() < exp * 1000 )
		{
			return true;
		}
	}
	return false;
}

export function getLoginToken ( authRecord )
{
	return ( isLoggedIn ( authRecord ) ? authRecord.token : null );
}

export function getUsername ( authRecord )
{
	return ( isLoggedIn ( authRecord ) ? authRecord.username : null );
}

export function logout ( authRecord )
{
	writeAuthRecordToStorage ( null );
	authRecord.status = "logged out";
}

export function readStorageForAuthRecord ( defval )
{
	var result = defval;
	var existingVal = localStorage.getItem ( authRecordStorageName );
	if ( existingVal )
	{
		try
		{
			result = JSON.parse ( existingVal );
			// we just assume it's a legit object here...
		}
		catch ( error )
		{
			log.warn ( "Local storage held [" + authRecordStorageName + "] that wasn't valid JSON." );
		}
	}
	else
	{
		log.info ( "Local storage does not contain [" + authRecordStorageName + "]." );
	}
	return result;
}

export function writeAuthRecordToStorage ( data )
{
	if ( data )
	{
		log.info ( "Writing auth record..." );
		localStorage.setItem ( authRecordStorageName, JSON.stringify (data) );
	}
	else
	{
		log.info ( "Removing auth record..." );
		localStorage.removeItem ( authRecordStorageName );
	}
}

/**
 * Post credentials to the login service. onSuccess receives the JSON reply directly.
 * 
 * @param {*} username 
 * @param {*} password 
 * @param {*} onSuccess 
 * @param {*} onFailure 
 */
export function authPostLogin ( username, password, onSuccess, onFailure )
{
	log.info ( "Auth API: authPostLogin() entry" );

	var usernameLower = username.toLowerCase();
	try
	{
		fetch ( "/api/auth/login",
			{
				"method": "POST",
				"headers": { "Content-Type": "application/json" },
				"body": JSON.stringify ( { username: usernameLower, password } )
			} )
		.then ( ( response ) =>
		{
			if ( response.ok ) { return response.json (); }

			log.warn ( "response not ok: " + JSON.stringify ( response ) );
			throw new Error ( 'Something went wrong' );
		})
		.then ( ( responseJson ) =>
		{
			log.info ( "Auth API: " + JSON.stringify ( responseJson ) );
			onSuccess && onSuccess ( responseJson );
		})
		.catch ( ( error ) =>
		{
			log.warn ( "Auth API: caught " + error );
			onFailure && onFailure ( error );
		})
	}
	catch ( e )
	{
		log.warn ( "Auth API: " + JSON.stringify(e) );
		onFailure && onFailure ( { "error": e } );
	}

	log.info ( "authPostLogin() entry" );
}

/**
 * Start a password recovery 
 * 
 * @param {*} username 
 * @param {*} onSuccess 
 * @param {*} onFailure 
 * /
export function authStartRecovery ( username, onSuccess, onFailure )
{
	var usernameLower = username.toLowerCase();
	axios
		.post ( "/api/auth/reset", { email: usernameLower } )
		.then ( result => 
			{
				log.info ( "then callback" );
				try
				{
					if ( result.status === 200 ) 
					{
						log.info ( "auth reply: " + JSON.stringify ( result.data ) );
						onSuccess && onSuccess ( result.data );
					}
					else
					{
						log.warn ( "Auth API: " + result.status + " " + result.statusText );
						onFailure && onFailure ( result.data );
					}
				}
				catch ( error )
				{
					log.warn ( "Couldn't start recovery. " + JSON.stringify ( error ) );
					onFailure && onFailure ( { "error": error } );
				}
			} )
		.catch ( e =>
			{
				log.warn ( "Auth API: " + e.response );
				onFailure && onFailure ( { "error": e.response } );
			} )
	;
}
*/
/**
 * Finish a password recovery 
 * 
 * @param {*} tag
 * @param {*} newpass 
 * @param {*} onSuccess 
 * @param {*} onFailure 
 * /
export function authFinishRecovery ( tag, newpass, onSuccess, onFailure )
{
	axios
		.post ( "/api/auth/reset", { tag, newPassword: newpass } )
		.then ( result => 
			{
				try
				{
					if ( result.status === 200 ) 
					{
						log.info ( "auth reply: " + JSON.stringify ( result.data ) );
						onSuccess && onSuccess ( result.data );
					}
					else
					{
						log.warn ( "Auth API: " + result.status + " " + result.statusText );
						onFailure && onFailure ( result.data );
					}
				}
				catch ( error )
				{
					log.warn ( "Couldn't complete recovery. " + JSON.stringify ( error ) );
					onFailure && onFailure ( { "error": error } );
				}
			} )
		.catch ( e =>
			{
				log.warn ( "Auth API: " + e.response );
				onFailure && onFailure ( { "error": e.response } );
			} )
	;
}
*/
