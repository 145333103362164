
import { getLoginToken } from '../authContext/auth.js';
import log from 'loglevel';

export class ModelObject {
	constructor ( data )
	{
		this.fullJson = data;
	}

	get data ()
	{
		return this.fullJson.object.data;
	}

	get relations ()
	{
		return this.fullJson.object.relations;
	}
}

//
//	onFailure ( statusCode, statusMsg, body, error ), with statusCode=555 for a network failure
//
export function loadObject ( authRecord, path, onSuccess, onFailure )
{
	try
	{
		var authToken = getLoginToken ( authRecord );
		var headers =
		{
			"Authorization": "Bearer " + authToken
		};

		fetch ( "/api/model/" + path, { "headers": headers } )
			.then ( ( response ) =>
			{
				log.info ( "model API response: " + response.status + " " + response.statusText );

				if ( response.ok ) return response.json ();

				onFailure && onFailure ( response.status, response.statusText, {}, null );
				return null;
			})
			.then ( ( responseJson ) =>
			{
				if ( responseJson !== null ) 
				{
					log.info ( "Model API: " + JSON.stringify ( responseJson ) );
					onSuccess && onSuccess ( new ModelObject ( responseJson ) );
				}
			})
			.catch ( ( error ) =>
			{
				log.warn ( "Model API: caught " + error );
				onFailure && onFailure ( 555, error.message, {}, error );
			})
	}
	catch ( e )
	{
		log.warn ( "Model API: " + JSON.stringify(e) );
		onFailure && onFailure ( { "error": e } );
	}
}

//
//	onFailure ( statusCode, statusMsg, body, error ), with statusCode=555 for a network failure
//
export function putObject ( authRecord, path, objBody, onSuccess, onFailure )
{
	try
	{
		var authToken = getLoginToken ( authRecord );
		var headers =
		{
			"Authorization": "Bearer " + authToken
		};

		var reqOptions = {
			method: 'PUT',
			headers: headers,
			body: JSON.stringify ( objBody )
		};
		fetch ( "/api/model/" + path, reqOptions )
			.then ( ( response ) =>
			{
				log.info ( "model API response: " + response.status + " " + response.statusText );

				if ( response.ok ) return response.json ();

				onFailure && onFailure ( response.status, response.statusText, {}, null );
				return null;
			})
			.then ( ( responseJson ) =>
			{
				if ( responseJson !== null ) 
				{
					log.info ( "Model API: " + JSON.stringify ( responseJson ) );
					onSuccess && onSuccess ( new ModelObject ( responseJson ) );
				}
			})
			.catch ( ( error ) =>
			{
				log.warn ( "Model API: caught " + error );
				onFailure && onFailure ( 555, error.message, {}, error );
			})
	}
	catch ( e )
	{
		log.warn ( "Model API: " + JSON.stringify(e) );
		onFailure && onFailure ( { "error": e } );
	}
}
