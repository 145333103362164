
import { Leftbar } from "../../components/leftbar/Leftbar.js";

export function Home ( props )
{
	return <div className="row">
		<div className="col-1">
			<Leftbar selected="home" />
		</div>
		<div className="col-11">
			
		</div>
	</div>
}
