
import { useState } from 'react';
import { useAuth } from '../../../services/authContext/auth.js';
import { loadObject } from '../../../services/modelInterface/ModelInterface.js';
import log from 'loglevel';
import { InternalJsonView } from './internaljsonview/InternalJsonView.js';
import { OutboundRelations, InboundRelations } from './relation/Relation.js';

import './ObjectDisplay.css';

export function ObjectDisplay ( props ) {

	var authRecord = useAuth ();

	var [ loading, setLoading ] = useState ( false );
	var [ theObject, setTheObject ] = useState ( null );
	var [ objectErr, setObjectErr ] = useState ( null );

	const startLoadFn = () =>
	{
		setLoading ( true );
		loadObject ( authRecord.authRecord, props.modelPath,

			( mo ) =>
				{
					log.info ( "theObject returned: " + JSON.stringify ( mo.data ) );

					setLoading ( false );
					setTheObject ( mo );
					setObjectErr ( null );
				},

			( statusCode, statusMsg, body, error ) =>
				{
					setLoading ( false );
					setTheObject ( null );

					if ( statusCode === 555 )
					{
						setObjectErr ( error.message );
						log.warn ( error.message );
					}
					else
					{
						setObjectErr ( statusMsg );
						log.warn ( statusMsg );
					}
				}
			);
	};

	const loadingFn = () =>
	{
		return <span>loading...</span>;
	};

	const objectRenderFn = () =>
	{
		// {"filter":{},"inbound":{},"outbound":{"child":{"entries":[{"id":"LwpjaGlsZAovYXR0","remote":"/att"},{"id":"LwpjaGlsZAovbWF0aA==","remote":"/math"},{"id":"LwpjaGlsZAovbmV0c2lt","remote":"/netsim"},{"id":"LwpjaGlsZAovZm9v","remote":"/foo"}],"type":"set"}}}
		// "inbound":{"child":{"entries":[{"id":"LwpjaGlsZAovbWF0aA==","remote":"/"}],"type":"set"}"

		return <div className="object-render">
			<div className="row">
				<div className="col-2">
					<InboundRelations inboundRelns={theObject.relations.inbound} />
				</div>
				<div className="col-8">
					<InternalJsonView data={theObject.data} />
				</div>
				<div className="col-2">
					<OutboundRelations outboundRelns={theObject.relations.outbound} />
				</div>
			</div>
		</div>
	};

	var objectRendering = <></>;
	if ( loading )
	{
		objectRendering = loadingFn ();
	}
	else if ( objectErr !== null )
	{
		objectRendering = <span>error: {objectErr}</span>;
	}
	else if ( theObject !== null )
	{
		objectRendering = objectRenderFn ();
	}
	else
	{
		startLoadFn ();
	}

	return (
		<div className="row">
			<div className="col-12">
				{objectRendering}
			</div>
		</div>
	);
}
