
import React from "react";

import { Breadcrumbs } from './breadcrumbs/Breadcrumbs.js';
import { InputArea } from './inputarea/InputArea.js';
import { ObjectDisplay } from './objectdisplay/ObjectDisplay.js';

import './ModelBrowser.css';

import log from 'loglevel';

export function ModelBrowser ( props ) {

	function setModelPath ( path )
	{
		log.info ( "switch to " + path )
	};

	var modelPath = props.modelPath;

	return (
		<div className="row outer">
			<div className="col-12">
				<div className="row">
					<div className="col-12">
						<Breadcrumbs modelPath={modelPath} setModelPath={setModelPath} /> <InputArea />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<ObjectDisplay modelPath={modelPath} setModelPath={setModelPath} />
					</div>
				</div>
			</div>
		</div>
	);
}
