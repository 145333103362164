
import "bootstrap-icons/font/bootstrap-icons.css";

// {"child":{"entries":[{"id":"LwpjaGlsZAovbWF0aA==","remote":"/"}]

function Relation ( props ) {
	return <a href={"/model" + props.entry.remote}>{props.entry.remote}</a>;
}

function RelationSet ( props ) {

	var relnName = props.relnName;
	var relns = props.relns;
	var isInbound = props.isInbound;

	var lines = relns.entries.map ( ( entry ) =>
	{
		return <div className="row" key={entry.id}>
			<Relation entry={entry} />
		</div>;
	} );

	return <div className={"relation-section " + (isInbound?"relation-inbound":"")}>
		<h2>{relnName} <i className="bi bi-arrow-bar-right"></i></h2>
		{lines}
	</div>
}

export function OutboundRelations ( props ) {

	return Object.keys ( props.outboundRelns ).map ( ( relnName ) =>
	{
		return ( <RelationSet key={relnName} relnName={relnName} relns={props.outboundRelns[relnName]} /> );
	} );
}

export function InboundRelations ( props ) {
	return Object.keys ( props.inboundRelns ).map ( ( relnName ) =>
	{
		return ( <RelationSet key={relnName} relnName={relnName} relns={props.inboundRelns[relnName]} isInbound={true} /> );
	} );
}
