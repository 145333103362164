
import { useAuth, isLoggedIn, logout } from '../../services/authContext/auth.js';

import logo from '../../static/images/cio.png';

const styles = {
	headerImage: {
		maxHeight: "32px",
		height: "auto",
		width: "auto"
	}
};

function Header() {

	const ctxVal = useAuth ();

	const Logout = () =>
	{
		logout ( ctxVal.authRecord );
	};

	let loggedInBlock = isLoggedIn ( ctxVal.authRecord ) ?
		<span id="logoutSpan"><a className="nav-link" href="/" onClick={(e)=>Logout()}>logout</a></span> :
		<></>
	;

	return (
		<div>
			<header className="App-header">
				<nav className="navbar navbar-expand-lg bg-body-tertiary">
					<div className="container-fluid">

						<a className="navbar-brand" href="/"><img style={styles.headerImage} src={logo} alt="Continual Model" /></a>

						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
							<div className="navbar-nav">
								{loggedInBlock}
							</div>
						</div>

					</div>
				</nav>
			</header>
		</div>
	);
}

export default Header;

/*
<!--
	<a class="nav-link active" aria-current="page" href="/">Model</a>
	<a class="nav-link" href="#">Features</a>
	<a class="nav-link" href="#">Pricing</a>
	<a class="nav-link disabled" aria-disabled="true">Disabled</a>
-->
*/
