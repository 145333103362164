
import React, { useState } from "react";
import { useAuth, authPostLogin } from "../../services/authContext/auth.js";
import log from 'loglevel';
import './Login.css';

export function Login ( props )
{
	const [username, setUserName] = useState ( "" );
	const [password, setPassword] = useState ( "" );

	const ctxVal = useAuth ();

	const onUsernameChange = function ( txt )
	{
		setUserName ( txt ); 
		clearNotification ();
	}

	const onPasswordChange = function ( txt )
	{
		setPassword ( txt ); 
		clearNotification ();
	}

	const notifyUser = function ( msg )
	{
		// var newList = userNotifications.concat ( [ msg ] );
		// setUserNotifications ( newList );
		log.warn ( "NOTIFY: " + msg );
	}

	const clearNotification = function ()
	{
		// var replacement = Array.from ( userNotifications );
		// replacement.pop ();
		// setUserNotifications ( replacement );
	}

	const doLogin = function ()
	{
		if ( username === null || username === "" )
		{
			notifyUser ( "Please enter a username." );
			return;
		}

		if ( password === null || password === "" )
		{
			notifyUser ( "Please enter a password." );
			return;
		}

		authPostLogin ( username, password,
			function ( responseData )
			{
				ctxVal.setAuthRecord ( responseData );
			},
			function ( responseData )
			{
				notifyUser ( "There was a problem with your login." );
			}
		);

		log.info ( "doLogin() returning" );
	}

	const takeAction = function ( e )
	{
		e.preventDefault ();

		// if ( inRecovery )
		// {
		// 	doRecovery ();
		// }
		// else
		//{
			doLogin ();
		//}
	}

	return <div className="contentPane">
		<div className="row loginForm">
			<div className="col-10">
				<form>
					<div className="mb-3">
						<label htmlFor="usernameInput" className="form-label">Email address</label>
						<input type="email"
							className="form-control" id="usernameInput"
							aria-describedby="emailHelp"
							onChange={ e => { onUsernameChange(e.target.value); } }
						/>
					</div>
					<div className="mb-3">
						<label htmlFor="passwordInput" className="form-label">Password</label>
						<input type="password" className="form-control" id="passwordInput"
							onChange={ e => { onPasswordChange(e.target.value); } }
						/>
					</div>
					<div>
						<button type="button" className="btn btn-primary loginButton" onClick={takeAction}>Login</button>
					</div>
				</form>
			</div>
		</div>
	</div>;
}
