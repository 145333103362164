
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthContext, readStorageForAuthRecord, writeAuthRecordToStorage, isLoggedIn } from './services/authContext/auth';

import Header from './components/header/Header.js';
import Footer from './components/footer/Footer.js';

import { Home } from './scenes/home/Home.js';
//import { IdentityContext, NoLogin, IsLoggedIn, IdentityProvider } from './services/IdentityProvider/IdentityProvider.js';
//import { AuthProvider } from './services/Auth/Auth.js';
import { Login } from './scenes/login/Login.js';
import { Settings } from './scenes/settings/Settings.js';
import { ModelBrowserScene } from './scenes/modelbrowserscene/ModelBrowserScene.js';

export function App ( props )
{
	// read local storage for an auth record and bring that into app state
	const existingAuthRecord = readStorageForAuthRecord ( {} );
	const [authRecord, setAuthRecord] = useState ( existingAuthRecord );

	// when a component sets the auth token, we want to write it to the local storage too
	const updateAuthRecord = (data) =>
	{
		writeAuthRecordToStorage( data );
		setAuthRecord ( data );
	}

	let body = null;
	if ( !isLoggedIn ( authRecord ) ) 
	{
		body = <Login />;
	}
	else
	{
		body = <Routes>
			{/* <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
			<Route exact path='/login' element={Login} /> */}

			<Route path='/model/*' element={<ModelBrowserScene/>} />
			<Route path='/settings/*' element={<Settings/>} />

			<Route path='/' element={<Home/>} />
			<Route element={<Home/>} />
		</Routes>
	}

	return <AuthContext.Provider value={ {authRecord, setAuthRecord: updateAuthRecord} }>
		<div className="container-fluid">
			<Header />
			<BrowserRouter>
				{body}
			</BrowserRouter>
			<Footer />

		</div>
	</AuthContext.Provider>;
}


/*
	<Route exact path='/login' component={Login} />

					{ / * <PrivateRoute exact path='/jobs' component={Jobs} />
					<PrivateRoute exact path='/jobs/:id' component={Job} /> * / }

					{ / * quotes * / }
					<PrivateRoute exact path='/quotes' component={Quotes} />
					<PrivateRoute exact path='/quotes/:quoteId' component={Quote} />

					{ / * schedules * / }
					<PrivateRoute exact path='/schedules' component={Schedules} />
					<PrivateRoute exact path='/schedules/:resId' component={Schedule} />

					{/* customers * / }
					<PrivateRoute exact path='/customers' component={Customers} />
					<PrivateRoute exact path='/customers/:custId' component={Customer} />

					{/* inks and materials * / }
					<PrivateRoute exact path='/inks' component={Inks} />
					<PrivateRoute exact path='/inks/:inkId' component={Ink} />
					<PrivateRoute exact path='/materials' component={Materials} />
					<PrivateRoute exact path='/materials/:matlId' component={Material} />

					{/* settings * / }
					{/* <PrivateRoute exact path='/settings' component={Settings} /> * / }
*/

// function PrivateRoute({ children }) {
// //	const { identity } = useContext(IdentityContext);
// 	return IsLoggedIn() ? <>{children}</> : <Navigate to="/login" />;
// }

