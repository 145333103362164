
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import './InternalJsonView.css';

export function InternalJsonView ( props ) {

	var localStyle = { ...defaultStyles };
	localStyle.container = "object-display-container";

//	log.info ( "InternalJsonView: " + JSON.stringify ( defaultStyles ) );

	return <JsonView data={props.data} shouldExpandNode={allExpanded} style={localStyle} />
}
